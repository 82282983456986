import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AttendeeViewModel } from '../../../core/backend/models';
import CreatorCard from '../../Cards/CreatorCard/CreatorCard';
import { useStores } from '../../../hooks';
import ExperienceModel from '../../../core/models/ExperienceModel';
import Button from '../../Button/Button';
import GoogleCalendarButton from '../../GoogleCalendarButton/GoogleCalendarButton';
import Attendees from '../../Attendees/Attendees';
import EventLocation from '../../EventLocation/EventLocation';
import AttendeesModal from '../../Modal/AttendeesModal/AttendeesModal';
import { convertToCurrency, formatUrl } from '../../../core/helpers';
import 'react-tabs/style/react-tabs.scss';
import './EventDetails.scss';
import { Address, IRecurrentEventData, IVideoModel } from '../../../core/types';
import Loader from '../../Loader/Loader';
import { useVideoModal } from '../../Modal/VideoModal/useVideoModal';
import { ReactComponent as BackIcon } from '../../../icons/back.svg';
import { ReactComponent as LinkOutIcon } from '../../../icons/link_out_icon.svg';
import { ReactComponent as PriceIcon } from '../../../icons/price_tag.svg';
import { ReactComponent as AboutIcon } from '../../../icons/details.svg';
import { ReactComponent as ClipsIcon } from '../../../icons/clips.svg';
import { ReactComponent as EmptyClipsIcon } from '../../../icons/clips_glyph.svg';
import ClipCard from '../../Cards/ClipCard/ClipCard';
import RecurrenceLoader from '../../Skeletons/RecurrenceLoader/RecurrenceLoader';
import { RecurrenceViewModel } from '../../../core/backend/models/recurrence-view-model';
import {
  addClicksToExternalEvent,
  getEventRecurrences,
  getGrammyEventId
} from '../../../core/api';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/validators';
import appToast from '../../../core/toast';
import RecurrentDateSelector from '../../RecurrentDateSelector/RecurrentDateSelector';
import { useExperienceModal } from '../../Modal/ExperienceModal/useExperienceModal';
import { TrackingEvents } from '../../../core/enums';
import GoogleTagManager from '../../../core/services/GoogleTagManager';
import TikTokPixel from '../../../core/services/TikTokPixel';
import MetaPixel from '../../../core/services/MetaPixel';
import { DATE_FORMAT } from '../../../core/consts';
import useQueryParams from '../../../hooks/useQueryParams';
import TextField from '../../TextField/TextField';
import ShowMore from '../../ShowMore/ShowMore';
import { TicketTypeOption } from '../../../core/backend/enums';
import UrlToAnchor from '../../UrlToAnchor/UrlToAnchor';

interface IProps {
  isLoadingRelatedClips?: boolean;
  event: ExperienceModel;
  requiresInvitationCode?: boolean;
  attendees: AttendeeViewModel[];
  hashtags: string[];
  bookedTickets: number;
  relatedClips: IVideoModel[];
  onGoBack?: () => void;
}

interface IAttendeesModal {
  isOpen: boolean;
  attendees?: AttendeeViewModel[];
}

const EventDetails = ({
  isLoadingRelatedClips,
  event,
  requiresInvitationCode = false,
  attendees = [],
  hashtags = [],
  bookedTickets = 0,
  relatedClips = [],
  onGoBack
}: IProps) => {
  const { userStore, bookingsStore, bookingStore } = useStores();
  const videoModal = useVideoModal();
  const { closeExperienceModal } = useExperienceModal();
  const [attendeesModal, setAttendeesModal] = useState<IAttendeesModal>({
    isOpen: false,
    attendees: null
  });
  const [isBuyTicketDisabled, setIsBuyTicketDisabled] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [eventRecurrentDates, setEventRecurrentDates] = useState<
    RecurrenceViewModel[]
  >([]);
  const [selectedEventData, setSelectedEventData] =
    useState<IRecurrentEventData>(null);
  const [isLoadingRecurrences, setIsLoadingRecurrences] = useState(false);
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const invitationCodeParam = queryParams.get('code');
  const [invitationCode, setInvitationCode] = useState<string>(
    invitationCodeParam ?? ''
  );

  useEffect(() => {
    loadEventData();
  }, [event]);

  useEffect(() => {
    setSelectedTabIndex(
      (event.isEventClosed || event.isSoldOut) && relatedClips.length > 0
        ? 1
        : 0
    );
  }, [relatedClips]);

  useEffect(() => {
    event.booking = userStore.user
      ? bookingsStore.getMostRecentBookingByExperience(event.id)
      : undefined;
  }, [event, userStore.user, bookingsStore.userBookings]);

  const loadEventData = async () => {
    //Get grammy event to validate if the user accessed the event directly
    // if (!bookingStore.isGrammyEvent) {
    //   const grammyEventId = await getGrammyEventId();

    //   if (event.id === grammyEventId)
    //     bookingStore.saveIsGrammyEventToStore(true);
    // }

    if (event.isRecurrent && !event.isDraft) {
      await getEventRecurrrenceDates();
    } else {
      setSelectedEventData(null);
      setEventRecurrentDates([]);
    }
  };

  const getEventRecurrrenceDates = async () => {
    try {
      setIsLoadingRecurrences(true);
      const dates = await getEventRecurrences(event.id, true);
      const firstActiveDate = dates.find(
        (date) => date.isActive && !date.isClosed && !date.isSoldOut
      );

      const firstEventData = convertRecurrenceDateData(firstActiveDate);

      const sortedRecurrences = dates
        .filter((recurrence) => !recurrence.isClosed)
        .sort((a, b) => a.startDateTime - b.startDateTime);

      setSelectedEventData(firstEventData);
      setEventRecurrentDates(sortedRecurrences);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsLoadingRecurrences(false);
    }
  };

  const convertRecurrenceDateData = (
    event: RecurrenceViewModel
  ): IRecurrentEventData => {
    const newEventData: IRecurrentEventData = { date: event };
    const eventAddress = event?.gigOptions;

    if (eventAddress?.city) {
      newEventData.address = {
        address: eventAddress?.address,
        address2: eventAddress?.address2,
        city: eventAddress?.city,
        state: eventAddress?.state,
        zip: eventAddress?.zip
      };
    }

    if (eventAddress?.latitude && eventAddress?.longitude) {
      newEventData.coords = {
        lat: eventAddress.latitude,
        lng: eventAddress.longitude
      };
    }

    return newEventData;
  };

  const handleBookEvent = async () => {
    try {
      if (
        event?.additionalData?.ticketTypeOption ===
        TicketTypeOption.externalLink
      ) {
        addClicksToExternalEvent(event.id);
        const externalUrl = formatUrl(
          event.additionalData?.ticketExternalLink ?? ''
        );
        return window.open(externalUrl?.formattedUrl, '_blank');
      }

      // if (
      //   userStore.user &&
      //   bookingStore.isGrammyEvent &&
      //   invitationCode?.trim().length === 0
      // ) {
      //   return appToast.showError('Invitation Code is Required');
      // }

      // if (userStore.user && bookingStore.isGrammyEvent && invitationCode) {
      //   setIsValidatingCode(true);
      //   await validateInvitationCode(event.id, invitationCode);
      // }

      bookingStore.saveDateDataToStore(selectedEventData);
      setSelectedEventData(null);

      GoogleTagManager.dataLayer({
        event: TrackingEvents.AddToCart,
        contentType: 'Event',
        userType: userStore.user ? 'Authenticated' : 'Guest'
      });

      MetaPixel.track('AddToCart', {
        content_name: event.name,
        content_type: 'product',
        content_ids: [event.id.toString()],
        value: event.hasCustomTickets ? event.ticketsStartingPrice : event.price
      });

      TikTokPixel.track('AddToCart', {
        content_id: event.id.toString(),
        content_name: event.name,
        content_category: 'Event',
        value: event.hasCustomTickets
          ? event.ticketsStartingPrice
          : event.price,
        currency: 'USD'
      });

      navigate(
        `/book/${event.id}${invitationCode ? '?code=' + invitationCode : ''}`
      );

      setInvitationCode('');
      setEventRecurrentDates([]);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsValidatingCode(false);
    }
  };

  const handleSelectEventDate = (selectedDate: RecurrenceViewModel) => {
    if (selectedDate.isClosed || selectedDate.isSoldOut) {
      return setIsBuyTicketDisabled(true);
    }

    isBuyTicketDisabled && setIsBuyTicketDisabled(false);

    const eventData = convertRecurrenceDateData(selectedDate);
    setSelectedEventData(eventData);
  };

  const handleShowAttendeesModal = (attendees: AttendeeViewModel[]) => () => {
    setAttendeesModal({ isOpen: true, attendees });
  };

  const handleCloseAttendeesModal = () => {
    setAttendeesModal({ isOpen: false, attendees: null });
  };

  const handleChangeInvitationCode = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInvitationCode(e.target.value);
  };

  const showActionButtons = userStore.user?.id !== event.creator.id;
  const showAddToCalendar =
    userStore.user && event.booking && !event.isEventClosed;

  const eventLocation: Address =
    event.isOneTimeEvent ||
    (event.isCreatorLocation && event.hasCoords) ||
    (event.isCustomerLocation && !event.IsAreaFlexible)
      ? event.location
      : (event.isFlexibleLocation && !event.isVirtual) ||
        (event.isCustomerLocation && event.IsAreaFlexible)
      ? userStore.addressFields
      : null;

  return (
    <div className='EventDetails'>
      {isValidatingCode && <Loader />}
      {onGoBack && (
        <div className='EventDetails__goBackButtonWrapper'>
          <Button
            className='EventDetails__goBackButton'
            variant='outline'
            size='auto'
            icon={<BackIcon className='EventDetails__goBackButtonIcon' />}
            onClick={onGoBack}
          >
            Video
          </Button>
        </div>
      )}
      <div className='EventDetails__wrapper'>
        <h1 className='EventDetails__title threeLinesText'>{event.name}</h1>

        {!event.isEventClosed && (
          <div
            className={classNames('EventDetails__eventDate', {
              'EventDetails__eventDate--closed': event.isEventClosed
            })}
          >
            {event.isRecurrent ? (
              `${dayjs
                .utc(
                  (selectedEventData?.date?.startDateTime * 1000 ||
                    event.eventStartDate) +
                    (selectedEventData?.date?.timeZoneOffsetInSeconds * 1000 ||
                      event?.eventTimezoneOffsetInMillis)
                )
                .format(DATE_FORMAT)} ${
                selectedEventData?.date?.timeZoneAbbreviation ??
                event?.dateTimezone
              }`
            ) : (
              <div className='EventDetails__eventStartEndDate'>
                <span>Starts</span>
                {`${dayjs
                  .utc(
                    event.eventStartDate + event?.eventTimezoneOffsetInMillis
                  )
                  .format(DATE_FORMAT)} ${event?.dateTimezone}`}
              </div>
            )}
          </div>
        )}

        <div className='EventDetails__info'>
          {!event.isEventClosed && event.isSoldOut && (
            <div className='EventDetails__eventSoldOut'>
              <PriceIcon />
              Sold Out
            </div>
          )}

          {event.isEventClosed && (
            <div className='EventDetails__eventClosed'>Event is closed</div>
          )}

          {!event.isSoldOut && !event.isEventClosed && (
            <div className='EventDetails__price'>
              <PriceIcon className='EventDetails__priceIcon' />
              <div className='EventDetails__currentPrice'>
                {event.priceLabel}
              </div>
            </div>
          )}
        </div>

        {!event.isEventClosed && isLoadingRecurrences ? (
          <RecurrenceLoader className='EventDetails__block EventDetails__pt-24' />
        ) : (
          eventRecurrentDates.length > 0 &&
          !!selectedEventData?.date && (
            <div className='EventDetails__block EventDetails__pt-24'>
              <h3 className='EventDetails__headers'>Select date and time</h3>
              <div className='EventDetails__description'>
                <RecurrentDateSelector
                  selectedDate={selectedEventData?.date}
                  dates={eventRecurrentDates}
                  onPress={handleSelectEventDate}
                />
              </div>
            </div>
          )
        )}

        {event.id !== 2350 && requiresInvitationCode && (
          <div className='EventDetails__block EventDetails__pt-24'>
            <h3 className='EventDetails__headers'>Invite Code</h3>
            <TextField
              value={invitationCode}
              disabled={!!invitationCodeParam}
              onChange={handleChangeInvitationCode}
            />
          </div>
        )}

        {showActionButtons && (
          <div className='EventDetails__actions'>
            {!event.isSoldOut && !event.isEventClosed && (
              <Button
                className='EventDetails__bookAction'
                icon={
                  event.additionalData?.ticketTypeOption ===
                  TicketTypeOption.externalLink ? (
                    <LinkOutIcon className='EventDetails__externalLinkIcon' />
                  ) : null
                }
                onClick={handleBookEvent}
                disabled={
                  isBuyTicketDisabled || event.isSoldOut || event.isEventClosed
                }
              >
                {(event.hasCustomTickets && event.ticketsStartingPrice === 0) ||
                (!event.hasCustomTickets && event.isFree)
                  ? 'Reserve Spot'
                  : 'Buy a Ticket'}
              </Button>
            )}
          </div>
        )}

        {showAddToCalendar && (
          <GoogleCalendarButton
            className='EventDetails__calendar EventDetails__pb-24'
            eventName={event.name}
            eventDescription={event.shortDescription}
            eventLocation={event.bookingAddress}
            eventDate={event.booking?.timestampUtc}
            eventDuration={event.booking?.duration}
          />
        )}

        {event.showWhoHasBook &&
          attendees.length > 0 &&
          event.additionalData?.ticketTypeOption !==
            TicketTypeOption.externalLink && (
            <div className='EventDetails__block EventDetails__pt-12 EventDetails__pb-12'>
              <h3 className='EventDetails__attendingHeader'>
                Booked Tickets: {bookedTickets}
              </h3>
              <div className='EventDetails__description'>
                {/* <AttendeesLoader /> */}
                <Attendees
                  attendees={attendees}
                  onViewAllClick={handleShowAttendeesModal(attendees)}
                />
              </div>
            </div>
          )}

        <Tabs
          className='EventDetails__tabs EventDetails__pt-12'
          selectedIndex={selectedTabIndex}
          onSelect={setSelectedTabIndex}
        >
          <TabList className='EventDetails__tabList'>
            <Tab className='EventDetails__tab'>
              <AboutIcon />
              <div className='EventDetails__tabLabel'>About</div>
            </Tab>
            <Tab className='EventDetails__tab'>
              <ClipsIcon />
              <div className='EventDetails__tabLabel'>Clips</div>
            </Tab>
          </TabList>
          <TabPanel>
            {event.description && (
              <div className='EventDetails__block EventDetails__separator'>
                <ShowMore
                  className='EventDetails__infoContent'
                  triggerClassName='EventDetails__showMore'
                  lines={5}
                >
                  <UrlToAnchor>{event.description}</UrlToAnchor>
                </ShowMore>
              </div>
            )}

            {event.whatToBring && (
              <div className='EventDetails__block EventDetails__separator'>
                <h3 className='EventDetails__headers'>What to Bring?</h3>
                <ShowMore
                  className='EventDetails__infoContent'
                  triggerClassName='EventDetails__showMore'
                  lines={5}
                >
                  <UrlToAnchor>{event.whatToBring}</UrlToAnchor>
                </ShowMore>
              </div>
            )}

            <div className='EventDetails__block EventDetails__separator'>
              <CreatorCard
                creator={event.creator}
                className='EventDetails__creator'
              />
            </div>

            <div className='EventDetails__block EventDetails__separator EventDetails__pt-24'>
              <h3
                className={classNames('EventDetails__headers', {
                  'EventDetails__headers--location': !event.isVirtual
                })}
              >
                {event.isVirtual ? 'Virtual' : 'Location'}
              </h3>
              {event.isVirtual ? (
                <div className='EventDetails__infoContent'>
                  The Experience Creator will provide you the link shortly
                </div>
              ) : event.isToBeAnnounced ? (
                <>
                  <div className='EventDetails__infoContent'>
                    To Be Announced
                  </div>
                  {!!event.formattedCityState && (
                    <div className='EventDetails__infoContent'>
                      {event.formattedCityState}
                    </div>
                  )}
                </>
              ) : (
                <EventLocation
                  className='EventDetails__location'
                  address={selectedEventData?.address || eventLocation}
                  coords={selectedEventData?.coords || event.coords}
                  serviceRadius={
                    event.isCustomerLocation && !event.IsAreaFlexible
                      ? event.serviceRadius
                      : null
                  }
                  displayDistance={!!event.isCreatorLocation}
                />
              )}
            </div>

            {hashtags.length > 0 && (
              <div className='EventDetails__block EventDetails__hashtags EventDetails__pt-24'>
                {hashtags.map((hashtag, idx) => (
                  <div
                    key={`${hashtags}-${idx}`}
                    className='EventDetails__hashtag'
                  >
                    <span className='EventDetails__hash'>#</span>
                    {hashtag}
                  </div>
                ))}
              </div>
            )}

            {!event.isVirtual && event.whatsIncluded && (
              <div className='EventDetails__block EventDetails__separator EventDetails__pt-24'>
                <h3 className='EventDetails__headers'>Parking Details</h3>
                <ShowMore
                  className='EventDetails__infoContent'
                  triggerClassName='EventDetails__showMore'
                  lines={5}
                >
                  <UrlToAnchor>{event.whatsIncluded}</UrlToAnchor>
                </ShowMore>
              </div>
            )}

            <div className='EventDetails__block EventDetails__pt-24'>
              <h3 className='EventDetails__headers EventDetails__policyHeader'>
                Cancellation Policy
              </h3>
              <div className='EventDetails__infoContent'>
                Please note that all one-time event bookings are non-refundable.
                Please ensure that you are able to commit to this date and time
                before proceeding.
              </div>
              <a
                className='EventDetails__policyLink'
                href='https://hoopla.com/terms-and-conditions'
                target='_blank'
              >
                Hoopla Terms of Service & Cancellation Policy
              </a>
            </div>
          </TabPanel>
          <TabPanel>
            <div className='EventDetails__clips EventDetails__pt-12'>
              {isLoadingRelatedClips ? (
                <div className='d-flex w-100 justify-content-center p-3'>
                  <Loader width='32px' fixed={false} showLogo={false} />
                </div>
              ) : relatedClips.length > 0 ? (
                relatedClips.map((clip, idx) => (
                  <ClipCard
                    key={`related-clip-${clip.id}`}
                    className='EventDetails__clip'
                    compact
                    clip={clip}
                    onPress={() => {
                      /**
                       * This fixes the overlap issue when video modal is open
                       * and the the event modal is open later and user tries to
                       * open a video from clips. Closes experience modal.
                       */
                      if (videoModal.isOpen) {
                        closeExperienceModal();
                      }

                      videoModal.openVideoModal({
                        media: relatedClips,
                        startAtIndex: idx
                      });
                    }}
                  />
                ))
              ) : (
                <div className='EventDetails__noContent'>
                  <span>There are no clips left yet</span>
                  <EmptyClipsIcon />
                </div>
              )}
            </div>
          </TabPanel>
          ;
        </Tabs>
      </div>
      <AttendeesModal
        isOpened={attendeesModal.isOpen}
        bookingCount={bookedTickets}
        attendees={attendeesModal.attendees}
        close={handleCloseAttendeesModal}
      />
      ;
    </div>
  );
};

export default EventDetails;
